export default ('paginate', function(totalPage, currentPage, range, options) {
    let context;
    let startPage = currentPage - Math.floor(range / 2);
    let endPage = currentPage + Math.floor(range / 2);
  
    if (startPage <= 0) {
        endPage -= (startPage - 1);
        startPage = 1;
    }
  
    if (endPage > totalPage) {
        endPage = totalPage;
        if (endPage - range + 1 > 0) {
            startPage = endPage - range + 1;
        } else {
            startPage = 1;
        }
    }
  
    context = {
        startFromFirstPage: false,
        pages: [],
        endAtLastPage: false,
    };
    
    if (startPage === 1) {
        context.startFromFirstPage = true;
    }

    for (var i = startPage; i <= endPage; i++) {
        context.pages.push({
            page: i,
            isCurrent: i === currentPage,
        });
    }

    if (endPage === totalPage) {
        context.endAtLastPage = true;
    }

    return options.fn(context);
});