<li class="menuItem-list-child{{#if children}} toggle js-tog{{/if}}" id="{{ id }}" >
    {{#if href}}
        {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
        <a href="{{href}}" class="menuItem-list-child-link">{{name}}</a>
    {{else}}
        {{#if children}}<span class="toggle-trigger js-togTrig"></span>{{/if}}
        <div class="menuItem-list-child-lbl">{{name}}</div>
    {{/if}}
    {{#if children}}
        <ul class="menuItem-list-child-list {{#if children}}toggle-target{{/if}}">
            {{#each children}}
                <li class="menuItem-list-child-list-item" id="{{ id }}">
                    {{#if href}}
                        <a href="{{href}}" class="menuItem-list-child-list-item-link">{{name}}</a>
                    {{else}}
                        <div class="menuItem-list-child-list-item-lbl">{{name}}</div>
                    {{/if}}
                </li>
            {{/each}}
        </ul>
    {{else}}
        <ul class="menuItem-list-child-list {{#if products}}toggle-target{{/if}}">
            {{#each products}}
                <li class="menuItem-list-child-list-item" id="{{ id }}">
                    {{#if href}}
                        <a href="{{href}}" class="menuItem-list-child-list-item-link">{{name}}</a>
                    {{else}}
                        <div class="menuItem-list-child-list-item-lbl">{{name}}</div>
                    {{/if}}
                </li>
            {{/each}}
        </ul>
    {{/if}}
</li>