import Message from "../messages/Message";

const REMOVE_DELAY = 4000;

window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, { preventCollaterateAction: true }, function (event, data) {
    const collaterateMessage = {
        body: data.message,
        isSuccess: false,
        isInfo: false,
        isWarning: false,
    }

    data.type === 'SUCCESS' && (collaterateMessage.isSuccess = true );
    data.type === 'INFO' && (collaterateMessage.isInfo = true );
    data.type === 'ERROR' && (collaterateMessage.isWarning = true );

    const messageInstance = new Message(collaterateMessage);

    setTimeout(() => messageInstance.remove(), REMOVE_DELAY);
});
