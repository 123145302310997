export default('dateCloserThan', function(compareDate, daysOut, options) {
    const today = new Date;
    const daysLeft = Math.round((compareDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

    if (daysLeft <= daysOut) {
        return options.fn(this);
    }
    return options.inverse(this);

 });
