<div class="styleWidget js--element-sWidget">
    <div class="styleWidget-trigger js--action-toggleStyleWidget"></div>
    <div class="styleWidget-main">
        <div class="styleWidget-main-hd">
            <div class="hdg hdg_5">Theme Settings</div>
            <div>
                <label class="styleWidget-main-hd-peristLabel" for="persistToggle">Persist Settings:</label>
                <input class="styleWidget-main-hd-persistToggle js--element--persistToggle" type="checkbox" {{#if savedSettingsPersist }}checked {{/if}}name="persistToggle" id="persistToggle">
            </div>
        </div>
        <ul class="styleWidget-main-current">
            <li>Set Theme Style: <span>{{ setThemeStyleName }}</span></li>
            <li>Set Brand Color: <span>{{ setBrandColor }}</span></li>
            <li>Set Logo File: <span>{{ setLogoName }}</span></li>
        </ul>
        <div class="styleWidget-main-setting">
            <div class="styleWidget-main-setting-color">
                <label for="brandColor">Brand Color:</label>
                <input class="js--element-brandColorPicker" type="color" name="brandColor" value="{{ setBrandColor }}" />
            </div>
            <div class="styleWidget-main-setting-logo">
                <label for="globalLogo">Upload Logo:</label>
                <input class="js--element-logoFileInput" type="file" name="globalLogo" />
            </div>
        </div>
        <div class="styleWidget-main-styles">
            <div class="styleWidget-main-styles-hd">Theme Style:</div>
            <ul class="styleWidget-main-styles-options js--element-themeStylePicker">
                <li class="{{#ifEquals setThemeStyleName "Light"}}isCurrent{{/ifEquals}}" data-theme-style-name="Light">Light</li>
                <li class="{{#ifEquals setThemeStyleName "Dark"}}isCurrent{{/ifEquals}}" data-theme-style-name="Dark">Dark</li>
                <li class="{{#ifEquals setThemeStyleName "Brand"}}isCurrent{{/ifEquals}}" data-theme-style-name="Brand">Brand</li>
                <li class="{{#ifEquals setThemeStyleName "Brand Contrast"}}isCurrent{{/ifEquals}}" data-theme-style-name="Brand Contrast">Brand Contrast</li>
            </ul>
        </div>
        <div class="styleWidget-main-actions">
            <button class="js--action-resetDefaults">Reset to defaults</button>
            <button class="js--action-toggleStyleWidget">Cancel</button>
            <button class="styleWidget-main-actions-save js--action-saveStyles">Save Settings</button>
        </div>
    </div>
</div>