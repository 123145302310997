import messageHtml from './message.hbs';

const container = document.querySelector('.collaterateInfo');

const CLOSE_BTN_SELECTOR = '.js-close-message';
const ELEMENT_INACTIVE_CLASS = 'isInactive';
const SHRINK_DELAY = 400;

class Message {
    constructor (message, onClose = ()=>{}) {
        this.element = createMessageElement(message);
        this.element.querySelector(CLOSE_BTN_SELECTOR).addEventListener('click', this.remove.bind(this, onClose), false);
    }

    remove (onClose = ()=>{}, e) {
        onClose();
        e && e.preventDefault && e.preventDefault();

        this.element.classList.add(ELEMENT_INACTIVE_CLASS);

        setTimeout(() => {
            const style = getComputedStyle(this.element);
            const totalHeight = this.element.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);

            const animateToClose = (from, to) => {
                if (Math.abs(from) === to) {
                    container.removeChild(this.element);
                    delete this.element;
                    return;
                }

                let newTarget = Math.floor((from - to) * 0.5);

                this.element.style.marginTop = `${newTarget}px`;

                requestAnimationFrame(() => animateToClose(newTarget, to));
            }

            return animateToClose(0, totalHeight);
        }, SHRINK_DELAY)
    }
}

function createMessageElement (message) {
    if (!container) {
        throw new Error('Message container element not found.');
    }

    container.insertAdjacentHTML('beforeend', messageHtml(message));
    return container.lastElementChild;
}

export default Message;
