import generateErrorHandler from "../../error/errorResponseHandler";

const contentBlockService = {
    getBlock: (blockName) => {
        return Collaterate.Site.getContentBlockByName(blockName)
            .then(contentBlock => contentBlock.content)
            .catch(generateErrorHandler());
    },

    getBlocks: (blockNames) => {
        return Promise.all(blockNames.map(name => this.getBlock(name)));
    },

    getCombinedBlocks: (blockNames) => {
        return this.getBlocks(blockNames)
            .then(content => content.join(''));
    }
};
export default contentBlockService;