import Handlebars from 'handlebars/runtime';
import checkLength from '../_helpers/checkLength';
import contains from '../_helpers/contains';
import convertBytes from '../_helpers/convertBytes';
import dollarsToCents from '../_helpers/dollarsToCents';
import dateCloserThan from '../_helpers/dateCloserThan';
import formatDate from '../_helpers/formatDate';
import ifEquals from '../_helpers/ifEquals';
import ifLessOrEqual from '../_helpers/ifLessOrEqual';
import lowercase from '../_helpers/lowercase';
import paginate from '../_helpers/paginate';
import removeMarkup from '../_helpers/removeMarkup';
import removeStyle from '../_helpers/removeStyle';
import truncateNumber from '../_helpers/truncateNumber';

import menuItemsHtml from '../menu/menuItems.partial.hbs'
import categoryItemsHtml from '../menu/categoryItems.partial.hbs'
import sfLogoHtml from '../_svgs/sfLogo.partial.hbs'

Handlebars.registerHelper('checkLength', checkLength);
Handlebars.registerHelper('contains', contains);
Handlebars.registerHelper('convertBytes', convertBytes);
Handlebars.registerHelper('dateCloserThan', dateCloserThan);
Handlebars.registerHelper('dollarsToCents', dollarsToCents);
Handlebars.registerHelper('formatDate', formatDate);
Handlebars.registerHelper('ifEquals', ifEquals);
Handlebars.registerHelper('ifLessOrEqual', ifLessOrEqual);
Handlebars.registerHelper('paginate', paginate);
Handlebars.registerHelper('removeMarkup', removeMarkup);
Handlebars.registerHelper('removeStyle', removeStyle);
Handlebars.registerHelper('lowercase', lowercase);
Handlebars.registerHelper('truncateNumber', truncateNumber);

Handlebars.registerPartial('menuItems', menuItemsHtml);
Handlebars.registerPartial('categoryItems', categoryItemsHtml);
Handlebars.registerPartial('sfLogo', sfLogoHtml);

export default Handlebars;