import Message from "../messages/Message";

export default function generateErrorHandler (callbacks = {}) {
    return function (e) {
        if (e.type) {
            if (e.type === 'LIST' && callbacks.list) {
                return callbacks.list(e);
            }

            if (e.type === 'RESPONSE' && callbacks.response) {
                return callbacks.response(e);
            }
        } else {
            if (callbacks.other) {
                return callbacks.other(e);
            }
        }

        new Message({
            body: e.message,
            isWarning: true,
        });
    };
};