import contentBlockService from "./contentBlockService/contentBlockService";
import contentBlockHtml from "../contentBlock/contentBlock.hbs";

class ContentBlock {
    constructor (container, blockName) {
        this.content = {};
        this.container = container;
        this.blockName = blockName;

        this.loadContentBlock()
            .then(this.renderMessage.bind(this));
    }

    loadContentBlock () {
        return contentBlockService.getBlock(this.blockName)
            .then( (content) => {
                if (content === '') {
                    this.container.remove();
                }
                this.content.value = content;
            })
    }

    renderMessage () {
        this.container.innerHTML = contentBlockHtml(this.content);
    }
}

export default ContentBlock;